<template>
  <div class="add-categories">
    <div class="add-categories-container">

      <div class="row d-flex align-items-center my-3">
        <div class="col-12 col-lg-6">
          <h5 class="font-main text-secondary">
            Lista de categorias creadas
          </h5>
        </div>
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <input 
              type="text"
              class="form-control input-custom"  
              placeholder="Escribe un nombre para crear una nueva categoría"
              v-model="new_category_name" 
              @keypress.enter="addNewCat()" @click="edit_category_id = null"
              maxlength="30" />
            <button  @click="addNewCat()"
            class="btn btn-custom-color-white border" 
            type="button">
              <font-awesome-icon 
                      icon="plus" 
                      class="color-secondary"
                    />
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="categories_list.length > 0">
   
        <ul class="list-group">
          <li v-for="(cat, k) in categories_list" :key="k" class="list-group-item d-flex justify-content-between align-items-center">
            <div class="text-secondary">
              <span v-if="edit_category_id !== cat.id_categoria">
                {{ cat.nombre_categoria }}
              </span>
              <span class="input-container" v-if="edit_category_id == cat.id_categoria">
                <input class="form-control input-custom" type="text" v-model="edit_category_name" maxlength="30" @keypress.enter="editCategory(cat)" />
              </span>
            </div>
            <!-- opciones editar/eliminar -->
            <div 
              v-if="edit_category_id !== cat.id_categoria"
              class="dropdown dropdown-custom d-flex justify-content-center">
              <button 
                class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                type="button" 
                data-bs-toggle="dropdown">
                <font-awesome-icon icon="ellipsis-vertical"/>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a 
                    @click="activeEditCat(cat)"
                    class="dropdown-item" 
                    href="javascript:"
                    :content='`Editar categoría "${cat.nombre_categoria}"`' v-tippy="{ arrow: true }" >
                    <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                    Editar
                  </a>
                </li>
                <li>
                  <a 
                    @click="beforeDeleteCategory(cat)"
                    class="dropdown-item" 
                    href="javascript:"
                    :content='`Eliminar categoría "${cat.nombre_categoria}"`' v-tippy="{ arrow: true }"
                    >
                    <font-awesome-icon class="pe-2 color-main" icon="trash-alt"/>
                    Eliminar
                  </a>
                </li>
              </ul>
            </div>
            <!-- opciones editar (cancelar/Guardar) -->
            <div v-if="edit_category_id == cat.id_categoria">
              <a
                href="javascript:"
                class="fs-5 me-2 btn btn-custom-color-white border"
                @click="edit_category_id = null"
              >
                <font-awesome-icon icon="circle-xmark" class="color-main"/>
              </a>
              <a
                href="javascript:"
                class="fs-5 btn btn-custom-color-white border"
                @click="editCategory(cat)"
              >
              <font-awesome-icon icon="circle-check" class="color-main"/>
            </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    </div>

    <Status v-if="open_modal_status" :msg="modal_status_msg" :status="modal_status" @close="closeStatus" />
    <Question v-if="open_question_modal" :msg="question_modal_msg" :hideCancel="false" @cancel="cancelQuestion"
      @accept="deleteCategory" />
    <Spinner v-if="show_spinner" />
  </div>
</template>
  
<script>
import Status from "../Modales/Status.vue";
import Question from "../Modales/Question.vue";
import Spinner from "../Spinner.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: { Status, Question, Spinner },
  data() {
    return {
      new_category_name: "",
      edit_category_name: "",
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      open_question_modal: false,
      question_modal_msg: "",
      edit_category_id: null,
      delete_category_id: null,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario: this.$ls.get("user").id_usuario,
      show_spinner: false,
    };
  },

  computed: {
    ...mapState("newsModule", ["categories"]),

    categories_list() {
      let filterCats = this.categories.filter((cat) => cat.especial === "0");
      return filterCats;
    }
  },
  created() {
  this.getCategories()
  },
  methods: {
    ...mapActions("newsModule", [
        "deleteNewsCategory", 
        "addNewsCategory", 
        "editNewsCategory",
        "getNewsCategories"
      ]),
     getCategories(){
      this.getNewsCategories({ id_empresa: this.$ls.get("user").empresa[0].id_empresa })

     },
    addNewCat() {
      if (this.new_category_name !== "") {
        this.show_spinner = true;
        const data = {
          id_usuario: this.id_usuario,
          id_empresa: this.id_empresa,
          nombre_categoria: this.new_category_name
        };
        this.addNewsCategory(data).then((res) => {
          this.show_spinner = false;
          if (!res) {
            this.$toast.open({
              message: 'Ocurrió un error al crear la categoría. Por favor inténtalo nuevamente.',
              type: 'error',
              duration: 4000,
              position: 'top-right'
            });
          } else {
            this.$toast.open({
              message: 'Categoría creada correctamente.',
              type: 'success',
              duration: 6000,
              position: 'top-right'
            });
          }
        }).finally(() => {
          this.new_category_name = "";
        });
      }
    },

    // Modal con pregunta antes de eliminar
    beforeDeleteCategory(cat) {
      this.delete_category_id = cat.id_categoria;
      this.open_question_modal = true;
      this.question_modal_msg = "¿Realmente quieres eliminar esta categoría?  &nbsp;";
    },

    cancelQuestion() {
      this.open_question_modal = false;
    },

    deleteCategory() {
      this.show_spinner = true;
      const data = {
        id_usuario: this.id_usuario,
        id_empresa: this.id_empresa,
        id_categoria: this.delete_category_id,
      };
      this.deleteNewsCategory(data).then((res) => {
        this.open_question_modal = false;
        this.show_spinner = false;
        if (!res) {
          this.$toast.open({
            message: 'Ocurrió un error al eliminar la categoría. Por favor inténtalo nuevamente.',
            type: 'error',
            duration: 4000,
            position: 'top-right'
          });
        } else {
          if (res.id_categoria === "0") {
            this.$toast.open({
              message: 'La categoría no pudo ser eliminada ya que tiene noticias asociadas.',
              type: 'error',
              duration: 4000,
              position: 'top-right'
            });
          } else {
            this.$toast.open({
              message: 'Categoría eliminada correctamente.',
              type: 'success',
              duration: 6000,
              position: 'top-right'
            });
          }
        }
      }).finally(() => {
        this.delete_category_id = null;
      });
    },

    // Activa opciones de editar y muestra input con nombre de categoría
    activeEditCat(cat) {
      this.edit_category_id = cat.id_categoria;
      this.edit_category_name = cat.nombre_categoria;
    },

    editCategory(category) {
      this.show_spinner = true;
      const data = {
        id_usuario: this.id_usuario,
        id_empresa: this.id_empresa,
        id_categoria: category.id_categoria,
        nombre_categoria: this.edit_category_name
      }
      this.editNewsCategory(data).then(res => {
        if (!res) {
          this.show_spinner = false;
          this.$toast.open({
            message: 'Ocurrió un error al editar la categoría.',
            type: 'error',
            duration: 4000,
            position: 'top-right'
          });
        } else {
          this.show_spinner = false;
          if (res.id_categoria) {
            this.$toast.open({
              message: 'Categoría editada correctamente.',
              type: 'success',
              duration: 6000,
              position: 'top-right'
            });
          }
        }
      }).finally(() => {
        this.edit_category_id = null;
      });

    },

    closeStatus() {
      this.open_modal_status = false;
      this.edit_category_id = null;
    },
  },
};
</script>
  